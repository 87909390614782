:root {
  --white: hsl(0, 6%, 87%);
  --white-light: hsl(0, 0%, 69%);
  --black-light: hsl(0, 1%, 24%);
  --black-dark: hsl(0, 2%, 19%);
  --green: hsl(120, 70%, 63%);
  --red: hsl(0, 97%, 72%);
}

*,
*::before,
*::after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}

body {
  background-color: hsl(0, 1%, 24%);
  color: rgb(250, 247, 247);
}

main {
  min-height: 60vh; /*Loading placed ~ mid*/
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-template-rows: auto;
  gap: 2em;
  padding: 3em 2em;
  position: relative;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* @media screen and (max-width: 1100px) {
  header {
    flex-direction: column;
  }
  header span {
    margin: 1em;
  }
  header > div {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    justify-content: center;
    align-items: center;
  }
} */
