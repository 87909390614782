button {
  padding: 0.5em 1em;
  border-radius: 15px;
  border: none;
  font-size: 1em;
  margin: 1em 0.5em;
  cursor: pointer;
}

button.active {
  background-color: var(--black-dark);
  transform: scale(1.1);
  box-shadow: 1px 0 5px 1px var(--white);
  color: var(--white);
}
