@media screen and (min-width: 800px) {
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* flex-wrap: wrap; */
    position: relative;
  }
}

header {
  width: 100%;
  padding: 1em;
  box-shadow: 1px 0 5px 1px var(--white);
}

header > div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

header p {
  font-size: 2.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
}

header p > * {
  animation: rotate infinite 20s linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
