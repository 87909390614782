.card {
  padding: 1em;
  background-color: var(--black-dark);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  box-shadow: 1px 0 5px 1px rgb(39, 38, 38);
  border-radius: 15px;
  transition: all 250ms ease-in-out;
  cursor: pointer;
  max-height: 14em;
}

.card:hover,
.card.active {
  box-shadow: 1px 0 5px 1px var(--white);
  transition: all 250ms ease-in-out;
  transform: scale(1.05);
}

.card.won{
  box-shadow: 1px 0 5px 1px var(--green);
}
.card.lost{
  box-shadow: 1px 0 5px 1px var(--red);
}

.prediction {
  min-width: 50%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
}

.vs {
  font-size: 1.25em;
  margin: 1em 0;
  text-align: center;
}

.date {
  text-align: center;
  color: var(--white-light);
}

.card * {
  pointer-events: none;
}

.card.skeleton{
  height: 135px;
}

.vs.skeleton, .date.skeleton, .prediction.skeleton{
  opacity: .35;
}